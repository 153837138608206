import React from 'react'
import Hero from '../Hero'
import HeadlessIcon from './HeadlessIcon'

const HeroHeadless = ({ heroTextTitle, heroTextSmallText, ctaTitle, heroData }) => {
    return (
        <Hero
            heroTextTitle={heroTextTitle}
            heroTextSmallText={heroTextSmallText}
            ctaTitle={ctaTitle}
            heroData={heroData}
        >
            <HeadlessIcon />
        </Hero>
    )
}

export default HeroHeadless
