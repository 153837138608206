import Piece1 from '@images/serviceHero/headless/headless-piece-1.svg'
import Piece2 from '@images/serviceHero/headless/headless-piece-2.svg'
import Piece3 from '@images/serviceHero/headless/headless-piece-3.svg'
import Piece4 from '@images/serviceHero/headless/headless-piece-4.svg'
import Piece5 from '@images/serviceHero/headless/headless-piece-5.svg'
import Piece6 from '@images/serviceHero/headless/headless-piece-6.svg'
import Piece7 from '@images/serviceHero/headless/headless-piece-7.svg'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import React, { useEffect, useRef } from 'react'

const HeadlessIcon = () => {
    gsap.registerPlugin(ScrollTrigger)
    const ref = useRef(null)
    useEffect(() => {
        const element = ref.current
        gsap.fromTo(
            element.querySelector('.piece-1'),
            {
                top: '25%',
                left: '20px',
                ease: 'ease.out'
            },
            {
                top: 'calc(100% - 124px)',
                left: 'calc(40% - 35px)',

                scrollTrigger: {
                    trigger: element.querySelector('.wrap-pice'),
                    start: '0px',
                    end: '500px',
                    scrub: 0.8
                }
            }
        )

        gsap.fromTo(
            element.querySelector('.piece-2'),
            {
                top: '15%',
                left: '20px',
                ease: 'ease.out'
            },
            {
                top: 'calc(100% - 108px)',
                left: 'calc(40% + 38px)',
                scrollTrigger: {
                    trigger: element.querySelector('.wrap-pice'),
                    start: '0px',
                    end: '500px',
                    scrub: 0.8
                }
            }
        )

        gsap.fromTo(
            element.querySelector('.piece-3'),
            {
                top: '5%',
                left: '35%',
                ease: 'ease.out'
            },
            {
                top: 'calc(100% - 190px)',
                left: 'calc(40% + 28px)',
                scrollTrigger: {
                    trigger: element.querySelector('.wrap-pice'),
                    start: '0px',
                    end: '500px',
                    scrub: 0.8
                }
            }
        )

        gsap.fromTo(
            element.querySelector('.piece-4'),
            {
                top: '10%',
                left: '70%',
                ease: 'ease.out'
            },
            {
                top: 'calc(100% - 124px)',
                left: 'calc(40% + 111px)',
                scrollTrigger: {
                    trigger: element.querySelector('.wrap-pice'),
                    start: '0px',
                    end: '500px',
                    scrub: 0.8
                }
            }
        )

        gsap.fromTo(
            element.querySelector('.piece-5'),
            {
                top: '20%',
                left: '35%',
                ease: 'ease.out'
            },
            {
                top: 'calc(100% - 150px)',
                left: '40%',
                scrollTrigger: {
                    trigger: element.querySelector('.wrap-pice'),
                    start: '0px',
                    end: '500px',
                    scrub: 0.8
                }
            }
        )

        gsap.fromTo(
            element.querySelector('.piece-6'),
            {
                top: '20%',
                left: 'calc(35% + 39px)',
                ease: 'ease.out'
            },
            {
                top: 'calc(100% - 150px)',
                left: 'calc(40% + 38.8px)',
                scrollTrigger: {
                    trigger: element.querySelector('.wrap-pice'),
                    start: '0px',
                    end: '500px',
                    scrub: 0.8
                }
            }
        )

        gsap.fromTo(
            element.querySelector('.piece-7'),
            {
                top: '35%',
                left: '42%',
                ease: 'ease.out'
            },
            {
                top: 'calc(100% - 21px)',
                left: 'calc(40% - 50px)',
                scrollTrigger: {
                    trigger: element.querySelector('.wrap-pice'),
                    start: '0px',
                    end: '500px',
                    scrub: 0.8
                }
            }
        )
    }, [])
    return (
        <div
            ref={ref}
            className="hidden xl:block relative md:col-[1_/_2] md:row-[2_/_4]"
        >
            <div className="absolute top-[10%] wrap-pice"></div>
            <div className="piece-1 absolute z-[2] w-[calc(881px*0.04)]">
                <img alt="headless-piece" src={Piece1} />
            </div>
            <div className="piece-2 absolute z-[2] w-[calc(946px*0.04)]">
                <img alt="headless-piece" src={Piece2} />
            </div>
            <div className="piece-3 absolute z-[2] w-[calc(1384px*0.04)]">
                <img alt="headless-piece" src={Piece3} />
            </div>
            <div className="piece-4 absolute z-[2] w-[calc(881px*0.04)]">
                <img alt="headless-piece" src={Piece4} />
            </div>
            <div className="piece-5 absolute z-[2] w-[calc(2788px*0.04)]">
                <img alt="headless-piece" src={Piece5} />
            </div>
            <div className="piece-6 absolute z-[2] w-[calc(854px*0.04)]">
                <img alt="headless-piece" src={Piece6} />
            </div>
            <div className="piece-7 absolute z-[2] w-[calc(5293px*0.04)]">
                <img alt="headless-piece" src={Piece7} />
            </div>
        </div>
    )
}

export default HeadlessIcon
